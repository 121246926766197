<script lang="ts" setup>
import { vAutoAnimate } from "@formkit/auto-animate/vue";
import type { HTMLAttributes } from "vue";
import { provide } from "vue";
import { FORM_ITEM_INJECTION_KEY } from "./useFormField";

const props = defineProps<{
  class?: HTMLAttributes["class"];
}>();

const id = useId() ?? crypto.randomUUID();
provide(FORM_ITEM_INJECTION_KEY, id);
</script>

<template>
  <div v-auto-animate :class="cn('space-y-2', props.class)">
    <slot />
  </div>
</template>
