<script lang="ts" setup>
import { ErrorMessage } from "vee-validate";
import { toValue } from "vue";
import { useFormField } from "./useFormField";

const { name, formMessageId, isTouched } = useFormField();
</script>

<template>
  <ErrorMessage
    v-if="isTouched"
    :id="formMessageId"
    as="p"
    :name="toValue(name)"
    class="ml-1 text-xs font-medium text-error"
  />
</template>
