<script lang="ts" setup>
import type { HTMLAttributes } from "vue";
import type { LabelProps } from "radix-vue";
import { useFormField } from "./useFormField";

const props = defineProps<LabelProps & { class?: HTMLAttributes["class"] }>();

const { error, formItemId, isTouched } = useFormField();
</script>

<template>
  <UILabel
    :class="cn(error && isTouched && 'text-error', props.class)"
    :for="formItemId"
  >
    <slot />
  </UILabel>
</template>
